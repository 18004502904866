import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const isRequiredString = (message?: any) => {
  const phone = 'phone number';
  const mobile = 'mobile number';
  const regex1 = new RegExp('\\b' + phone.replace(/\s+/g, '\\s+') + '\\b', 'i');
  const regex2 = new RegExp(
    '\\b' + mobile.replace(/\s+/g, '\\s+') + '\\b',
    'i',
  );
  if (
    regex1.test(message?.toLowerCase()) ||
    regex2.test(message?.toLowerCase())
  ) {
    return Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
      .required(message);
  } else {
    return Yup.string().required(message);
  }
};

export const isRequiredNumber = (message?: string) =>
  Yup.number().required(message);

export const isRequiredEmail = (message?: string) =>
  Yup.string().email(message).required()?.label('Email');

export const isRequiredNumberGreaterThan0 = (
  requiredMessage?: string,
  constraintMessage?: string,
) => Yup.number().min(1, constraintMessage).required(requiredMessage);

export const isRequiredMobileNumber = (message?: string) =>
  Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
    .required(message);


export const getImageValidationSchema = (errorMessage = "Image is Required") => Yup.object().shape({
  orignalImage: Yup.string(),
  selectedImage: Yup.string(),
  enhancedImage: Yup.string(),
}).test("at-least-one-image", errorMessage, (values: any) =>
  values?.orignalImage || values?.selectedImage || values?.enhancedImage
);