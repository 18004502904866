import React, { useState } from "react";

const ImageHandler = ({ campusElement, setCampusElement, disable }) => {
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const handleRemoveImage = (index: number) => {
        const updatedImages = [...(campusElement?.SELECTED_CAMPUS_IMAGES || [])];
        updatedImages.splice(index, 1);
        setCampusElement(updatedImages);
    };

    return (
        <div className="flex justify-center items-center gap-4">
            {[1, 2, 3].map((_, index) =>
                !!campusElement?.SELECTED_CAMPUS_IMAGES?.[index]?.selectedImage ? (
                    <div key={index} className="relative group">
                        <img
                            src={campusElement?.SELECTED_CAMPUS_IMAGES?.[index]?.selectedImage}
                            alt="Uploaded"
                            className="w-20 h-20 border border-solid rounded-lg object-contain cursor-pointer"
                            onClick={() => setPreviewImage(campusElement?.SELECTED_CAMPUS_IMAGES?.[index]?.selectedImage)}
                        />
                        {/* Remove Button */}
                        {!disable && <button
                            className="absolute top-0 right-0 bg-[red] text-[white] w-5 h-5 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition"
                            onClick={() => handleRemoveImage(index)}
                        >
                            ✕
                        </button>}
                    </div>
                ) : (
                    <div key={index} className="w-20 h-20 border border-solid rounded-lg bg-gray-400"></div>
                )
            )}

           {/* Preview Modal */}
           {previewImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
                        {!disable && <button
                            className="absolute top-2 right-2 bg-[red] text-[white] w-6 h-6 flex items-center justify-center rounded-full"
                            onClick={() => setPreviewImage(null)}
                        >
                            ✕
                        </button>}
                        <img src={previewImage} alt="Preview" className="max-w-full max-h-[80vh] object-contain mx-auto" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageHandler;
