import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from 'yup';
import { getImageValidationSchema } from "constants/validationSchemas";

const Recognition = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
    const maxForms = 5;

    const emptyRecognitionData = {
        RECOGNITION_IMAGE: "",
        RECOGNITION_ACHIEVEMENT: "",
        RECOGNITION_ISSUED_BY: "",
        RECOGNITION_YEAR: "",
        RECOGNITION_DESCRIPTION: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(sessionStorage.getItem("recognitions")) || [];
        return savedData.length ? savedData : [emptyRecognitionData];
    };

    const validationSchema = Yup.object({
        recognitions: Yup.array().of(
            Yup.object({
                RECOGNITION_IMAGE: getImageValidationSchema("Recognition image is required"),
                RECOGNITION_ACHIEVEMENT: Yup.string().required("Recongnition achievement is required"),
                RECOGNITION_ISSUED_BY: Yup.string().required("Recognition issued by is required"),
                RECOGNITION_YEAR: Yup.string().required("Recognition year is required"),
                // RECOGNITION_DESCRIPTION: Yup.string().required("Recoequired"),
            })
        )
    })

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ recognitions: loadInitialData() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        // console.log("values.recognitions ",values.recognitions)
                        sessionStorage.setItem("recognitions", JSON.stringify(values.recognitions));
                        handleNavigate()
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">

                            {/* Insert Button Positioned Above the Forms */}
                            {/* {values.recognitions.length < maxForms && (
                                    <div className="absolute top-2 right-2 z-10">
                                        <FieldArray name="recognitions">
                                            {({ push }) => (
                                                <button
                                                    type="button"
                                                    onClick={() => push(emptyRecognitionData)}
                                                    className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                                    >
                                                        +
                                                </button>
                                            )}
                                        </FieldArray>
                                    </div>
                                )} */}
                            <div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <div className="mx-2 w-[80%] text-center">
                                        <p className="text-sm font-bold mb-0.7">
                                            Celebrate Your School's Achievements!
                                        </p>
                                        <p className="text-xs font-thin">
                                            Show off the accolades, awards, and recognitions that make your school stand out.
                                        </p>
                                    </div>
                                    <p className="text-xs italic text-gray-500 font-thin" /* style={{color: "white"}} */>
                                        (Maximum 5 Recognition Details)
                                    </p>
                                </div>}



                                <div className="rounded-2xl my-1 mx-1">
                                    <FieldArray name="recognitions">
                                        {({ push, remove }) => (
                                            <div className="w-full">
                                                <HorizontalFormWrapper
                                                    cardSpacing={30}
                                                    showGhostCard={true}
                                                    onGhostCardClick={() => push(emptyRecognitionData)}
                                                    maxItems={maxForms}
                                                    currentItemsCount={values.recognitions.length}
                                                    disable={disableForm}
                                                >
                                                    {values.recognitions.map((recognitionElement, index) => (
                                                        <FormCard
                                                            key={index}
                                                            onDelete={values.recognitions.length > 1 ? () => remove(index) : undefined}
                                                        >
                                                            <Field name={`recognitions.${index}.RECOGNITION_IMAGE`}>
                                                                {({ field, form }) => (
                                                                    <ImageUpload
                                                                        disable={disableForm}
                                                                        labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                                                        imageValue={field.value}
                                                                        onChange={(item) => {
                                                                            form.setFieldValue(`recognitions.${index}.RECOGNITION_IMAGE`, item);
                                                                        }}
                                                                        wantHDOption
                                                                        containerHeight={{
                                                                          width: '7vh',
                                                                          height: '7vh',
                                                                      }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name={`recognitions.${index}.RECOGNITION_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />

                                                            <Input
                                                                disable={disableForm}
                                                                label="What's Your Achievement?"
                                                                name={`recognitions.${index}.RECOGNITION_ACHIEVEMENT`}
                                                                type="text"
                                                                placeHolder="e.g., Telangana Educational Excellence Award"
                                                                required

                                                            />

                                                            <Input
                                                                disable={disableForm}
                                                                label="Who Issued the Award?"
                                                                name={`recognitions.${index}.RECOGNITION_ISSUED_BY`}
                                                                type="text"
                                                                placeHolder="e.g., Indian Government"
                                                                required

                                                            />

                                                            <Input
                                                                disable={disableForm}
                                                                label="In Which Year?"
                                                                name={`recognitions.${index}.RECOGNITION_YEAR`}
                                                                type="text"
                                                                placeHolder="e.g., 2022-2023"
                                                                required

                                                            />

                                                            <TextArea
                                                                disabled={disableForm}
                                                                label="A Few Words About The Achievement"
                                                                name={`recognitions.${index}.RECOGNITION_DESCRIPTION`}
                                                                placeHolder=""
                                                                // customHeight="60px"
                                                                rows={3}
                                                                // autoGenerate={true}
                                                            />
                                                        </FormCard>
                                                    ))}
                                                </HorizontalFormWrapper>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Buttons Section */}
                            {!disableForm && <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Recognition;