import React from "react";
import FormWrapper from "../component/FormWrapper";
import { Link } from "react-router-dom";
import LF from "../LandingForm/LF";
import Leaders from "pages/Onboarding/Leaders";
import Faculties from "../Faculties";
import Recognition from "../Recognition";
import CampusInfo from "../CampusInfo";
import ChatBot from "../ChatBot";
import Testimonials from "../Testimonials";
import Brochu from "../Brochures/Brochu";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

const SIDEBAR = [
  { label: "Your Website", route: "/website", value: true, component: "" },
  {
    label: "School Info",
    route: "/onboarding",
    value: sessionStorage.getItem("Institute"),
    id: "Institute",
    component: (
      <LF
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Founder's Info",
    route: "/onboarding/leaders",
    value: sessionStorage.getItem("founders"),
    id: "founders",
    component: (
      <Leaders
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Teachers Info",
    route: "/onboarding/faculties",
    value: sessionStorage.getItem("faculties"),
    id: "faculties",
    component: (
      <Faculties
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Recognitions",
    route: "/onboarding/recognition",
    value: sessionStorage.getItem("recognitions"),
    id: "recognitions",
    component: (
      <Recognition
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Campus Info",
    route: "/onboarding/campusinfo",
    value: sessionStorage.getItem("campus"),
    id: "campus",
    component: (
      <CampusInfo
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Chatbot",
    route: "/onboarding/chatbot",
    value: sessionStorage.getItem("Chatbot"),
    id: "Chatbot",
    component: (
      <ChatBot
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Testimonials",
    route: "/onboarding/testimonials",
    value: sessionStorage.getItem("testimonials"),
    id: "testimonials",
    component: (
      <Testimonials
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
  {
    label: "Branding",
    route: "/onboarding/brochure",
    value: sessionStorage.getItem("materials"),
    id: "materials",
    component: (
      <Brochu
        handleNavigate={""}
        resetFormData={""}
        titleAndDesNotRequired={true}
        disableForm={true}
      />
    ),
  },
];

const Preview = ({
  handleNavigate,
  resetFormData,
}: {
  handleNavigate;
  resetFormData?: any;
}) => {
  // const navigate = useNavigate();

  const [loader, setLoader] = React.useState(false);
  const handleSubmit = async () => {
    try {
      setLoader((loader) => true);
      const institute = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "Institute")?.value as any
      );
      const InstituteBranchBasicInfo = {
        INSTITUTE_TYPE_NAME: institute?.INSTITUTE_TYPE,
        INSTITUTE_BRANCH_NAME: institute?.NAME,
        INSTITUTE_DOMAIN: institute?.DOMAIN_NAME,
        INSTITUTE_ACTIVATION_DATE: moment().format("YYYY-MM-DD"),
        InstituteBranchDetails: {
          INSTITUTE_BRANCH_LOGO: institute?.LOGO?.selectedImage,
          INSTITUTE_BRANCH_COUNTRY_CODE: institute?.COUNTRY_CODE,
          INSTITUTE_BRANCH_PHONE: institute?.MOBILE,
          INSTITUTE_BRANCH_EMAIL_ID: institute?.EMAIL,
        },
        InstituteBranchLocation: {
          INSTITUTE_BRANCH_ADDRESS: institute?.GOOGLE_MAP_POSITION?.address,
          INSTITUTE_BRANCH_COUNTRY: institute?.GOOGLE_MAP_POSITION?.country,
          INSTITUTE_BRANCH_STATE: institute?.GOOGLE_MAP_POSITION?.state,
          INSTITUTE_BRANCH_CITY:
            institute?.GOOGLE_MAP_POSITION?.city ||
            institute?.GOOGLE_MAP_POSITION?.state, // delhi case
          INSTITUTE_BRANCH_PINCODE:
            institute?.GOOGLE_MAP_POSITION?.postalCode || "",
          INSTITUTE_BRANCH_LONGITUDE: institute?.GOOGLE_MAP_POSITION?.lng,
          INSTITUTE_BRANCH_LATITUDE: institute?.GOOGLE_MAP_POSITION?.lat,
        },
        INSTITUTE_BOARD_TYPE: institute?.BOARD_TYPE,
        INSTITUTE_FOUNDING_DATE: moment(institute?.FOUNDING_DATE).format(
          "YYYY-MM-DD"
        ),
        INSTITUTE_INSTRUCTION_MEDIUM: institute?.INSTRUCTION_MEDIUM,
        INSTITUTE_GRADE_LEVELS: institute?.GRADE_LEVELS,
        INSTITUTE_BRANCH_ADDONS: institute?.ADDON_PROGRAMS,
      };
      const founders = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "founders")?.value as any
      )?.map((item) => ({
        FounderUser: {
          NAME: item?.INSTITUTE_LEADER_NAME,
          GENDER: item?.GENDER,
          COUNTRY_CODE: item?.COUNTRY_CODE,
          MOBILE_NUMBER: item?.INSTITUTE_LEADER_MOBILE,
          EMAIL_ID: item?.INSTITUTE_LEADER_EMAIL,
          PHOTO_URL: item?.INSTITUTE_LEADER_IMAGE?.selectedImage,
        },
        DESCRIPTION: item?.INSTITUTE_LEADER_DESCRIPTION,
        DESIGNATION: item?.INSTITUTE_LEADER_DESIGNATION,
        QUALIFICATION: item?.INSTITUTE_LEADER_QUALIFICATION,
        EXPERIENCE: item?.INSTITUTE_LEADER_EXPERIENCE,
      }));
      const faculties = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "faculties")?.value as any
      )?.map((item) => ({
        FacultyUser: {
          NAME: item?.FACULTY_NAME,
          GENDER: item?.GENDER,
          COUNTRY_CODE: item?.COUNTRY_CODE,
          MOBILE_NUMBER: item?.FACULTY_MOBILE,
          EMAIL_ID: item?.FACULTY_EMAIL,
          PHOTO_URL: item?.FACULTY_IMAGE?.selectedImage,
        },
        FACULTY_SUBJECT: item?.FACULTY_SUBJECT,
        FACULTY_QUALIFICATION: item?.FACULTY_QUALIFICATION,
        FACULTY_EXPERIENCE: item?.FACULTY_EXPERIENCE,
        FACULTY_DESCRIPTION: item?.FACULTY_DESCRIPTION,
      }));
      const recognitions = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "recognitions")?.value as any
      )?.map((item) => ({
        RECOGNITION_IMAGE: item?.RECOGNITION_IMAGE?.selectedImage,
        RECOGNITION_ACHIEVEMENT: item?.RECOGNITION_ACHIEVEMENT,
        RECOGNITION_ISSUED_BY: item?.RECOGNITION_ISSUED_BY,
        RECOGNITION_YEAR: item?.RECOGNITION_YEAR,
        RECOGNITION_DESCRIPTION: item?.RECOGNITION_DESCRIPTION,
      }));
      const campus = {
        ABOUT_CAMPUS: JSON.parse(
          SIDEBAR?.find((item) => item?.id === "campus")?.value as any
        )?.[0]?.ABOUT_CAMPUS,
        CAMPUS_IMAGE_DETAILS: JSON.parse(
          SIDEBAR?.find((item) => item?.id === "campus")?.value as any
        )?.map((item) => ({
          IMAGE_TAG: item?.IAMGE_FOR,
          // "CAMPUS_IMAGE_URL": item?.CAMPUS_IMAGE,
          SELECTED_CAMPUS_IMAGES: item?.SELECTED_CAMPUS_IMAGES?.map(
            (item) => item?.selectedImage
          ),
        })),
      };
      // const Chatbot = {
      //   AVATAR_URL:
      //     JSON.parse(
      //       SIDEBAR?.find((item) => item?.id === "Chatbot")?.value as any
      //     )?.image?.selectedImage || "",
      // };

      const storedChatbot = sessionStorage.getItem("Chatbot");
      const avatarUrl = storedChatbot ? JSON.parse(storedChatbot) : null;

      const Chatbot = {
        AVATAR_URL:
          avatarUrl?.type === "default"
            ? avatarUrl?.image
            : avatarUrl?.image?.selectedImage || "",
      };

      const testimonials = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "testimonials")?.value as any
      )?.map((item) => ({
        TESTIMONIALS_IMAGE: item?.TESTIMONIALS_IMAGE?.selectedImage,
        TESTIMONIALS_NAME: item?.TESTIMONIALS_NAME,
        TESTIMONIALS_TESTIMONIAL: item?.TESTIMONIALS_TESTIMONIAL,
      }));
      const materials = JSON.parse(
        SIDEBAR?.find((item) => item?.id === "materials")?.value as any
      )?.map((item) => ({
        MATERIAL_IMAGE: item?.MATERIAL_IMAGE?.selectedImage,
        MATERIAL_TYPE: item?.MATERIAL_TYPE,
        MATERIAL_NAME: item?.MATERIAL_NAME,
        MATERIAL_DESCRIPTION: item?.MATERIAL_DESCRIPTION,
      }));
      const payload = {
        InstituteBranchBasicInfo,
        InstituteBranchFounderDetails: founders,
        InstituteBranchFaculty: faculties,
        InstituteBranchRecognitions: recognitions,
        Campus: [campus],
        ChatBotConfig: Chatbot,
        Testimonials: testimonials,
        Branding: materials,
        CREATION_DATETIME: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      try {
        const response = await axios.post(
          `https://dev.soomuch.ai/api/v1/master/onboarding/process`,
          payload
        );
        if (["200", "201"]?.includes(String(response?.data?.code))) {
          setLoader((loader) => false);
          Swal.fire({
            icon: "success",
            title: response?.data?.message || "Onboarding Process Completed!",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            sessionStorage.clear();
            window.location.href = "/";
          });
        } else {
          setLoader((loader) => false);
          Swal.fire({
            icon: "error",
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } catch (err) {
        setLoader((loader) => false);
        Swal.fire({
          icon: "error",
          title: err?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (err) {
      setLoader((loader) => false);
    }
  };

  return (
    <FormWrapper
      childern={
        <div className="relative">
          <div className="bg-onboardingBackground rounded-3xl p-6 text-white space-y-3 border border-[2.5px] border-purple-gradientOBborder">
            <div
              className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */
            >
              <p className="text-[4vh] font-bold bg-clip-text text-white text-center">
                Review and Confirm Your Data
              </p>
              <div className="w-full text-center">
                <p className="text-[2vh] font-thin text-white">
                  Please review all the data carefully before submiting.
                </p>
              </div>
            </div>
            {SIDEBAR?.slice(1)?.map((item, index) => (
              <React.Fragment key={"ajdbkjahdbasjdbasmdbasdjasdsad" + index}>
                <div className="flex justify-between items-center bg-clip-text text-white border border-solid p-1 rounded-lg">
                  <p className="text-[3vh] font-bold ">{item?.label}</p>
                  <Link
                    type="button"
                    to={item?.route}
                    className="text-[2vh] border border-[black] rounded-lg py-1 px-3"
                  >
                    Edit
                  </Link>
                </div>
                {item?.component}
              </React.Fragment>
            ))}
          </div>
          {/* <ResetAndSubmit submitLabel="Submit" /> */}
          <div className="flex justify-center my-2">
            {loader ? (
              <img
                src={"/images/loader.gif"}
                className="w-10 h-10"
                alt="Loading"
              />
            ) : (
              <button
                type="button"
                className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-[2vh] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                style={{ color: "white" }}
                onClick={handleSubmit}
              >
                {"Submit"}
              </button>
            )}
          </div>
        </div>
      }
      formHeading={undefined}
    />
  );
};

export default Preview;
