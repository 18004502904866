import React from "react";

const ChatbotAvatarCard = ({
  item,
  handleOnClick,
  loadInitialData,
  disable,
}) => {
  return (
    <div
      style={{
        backgroundColor: Boolean(
          loadInitialData()?.image?.selectedImage ===
            item?.image?.selectedImage &&
            loadInitialData()?.value === item?.value
        )
          ? "#ba3ce9"
          : "",
      }}
      key={"asdnakdbasndasd" + item?.label}
      onClick={() => {
        if (!disable) handleOnClick();
      }}
      className={`cursor-pointer w-full h-fit border-[6px] border-solid rounded-xl text-center`}
    >
      {item?.type === "default" ? (
        item?.video ? (
          <video
            src={item?.video}
            // controls
            className="w-full h-[18vh] object-fit rounded-xl"
          />
        ) : (
          <img
            src={item?.image || "/images/ChatBotAvatar.png"}
            alt="ChatBotAvatar"
            className="w-full h-[18vh] object-fit rounded-xl"
          />
        )
      ) : (
        <img
          src={item?.image?.selectedImage || "/images/ChatBotAvatar.png"}
          alt="ChatBotAvatar"
          className="w-full h-[18vh] object-fit rounded-xl"
        />
      )}
      {/* <img
        src={item?.image?.selectedImage || "/images/ChatBotAvatar.png"}
        alt="ChatBotAvatar"
        className="w-full h-[18vh] object-fit rounded-xl"
      /> */}
      <p className={"text-xs text-center bg-[white] py-2 rounded"}>
        {item?.label}
      </p>
    </div>
  );
};

export default ChatbotAvatarCard;
