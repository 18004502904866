import React from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ResetAndSubmit = ({ resetForm, submitLabel }: { resetForm?: any, submitLabel?: string }) => {
    const params = useParams();
    return (
        <div className="flex justify-center items-center gap-4 mt-2 sm:justify-center">
            {!!resetForm && <button
                type="button"
                className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-[2vh] text-white py-2 px-7 rounded-lg hover:opacity-90 transition-opacity"
                onClick={() => {
                    Swal.fire({
                        title: `Do you want to reset ${!!params?.["*"] ? `${params?.["*"]} info` : "school info"}?`,
                        icon: "info",
                        showDenyButton: true,
                        // showCancelButton: true,
                        confirmButtonText: "YES",
                        denyButtonText: "NO"
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            resetForm()
                        }
                    });;
                }}
                style={{ color: 'white' }}
            >
                RESET
            </button>}
            <button
                type="submit"
                className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-[2vh] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                style={{ color: 'white' }}
            >
                {submitLabel || "PROCEED"}
            </button>
        </div>
    );
}

export default ResetAndSubmit;