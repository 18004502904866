// import React from "react";
// import Select from "react-select";
// import { useField } from "formik";

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[];
//   name: string;
//   placeHolder: string;
//   isMulti?: boolean;
//   labelClassNames?: string;
//   customHeight?: string;
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
//   disable?: boolean;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle,
//   disable
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {!!label && (
//         <label
//           className={`block ${labelClassNames || ""}`}
//           style={{
//             // color: "white",
//             ...(!!customHeight && { fontSize: `calc(${customHeight} / 3)` }),
//           }}
//         >
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}

//       <div className="w-full border border-solid border-black-900 rounded-lg">
//         <Select
//           isDisabled={disable}
//           menuPlacement="top"
//           options={options}
//           isMulti={isMulti}
//           onChange={(selectedOptions) =>
//             setValue(
//               isMulti
//                 ? (selectedOptions as { value: string; label: string }[]).map(
//                   (option) => option.value
//                 )
//                 : (selectedOptions as { value: string; label: string })?.value ||
//                 ""
//             )
//           }
//           placeholder={placeHolder}
//           menuPortalTarget={document.body}
//           styles={{
//             menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
//             control: (base) => ({
//               ...base,
//               // background: "linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%)",
//               borderRadius: "0.5rem",
//               ...(!!customHeight && {
//                 height: customHeight,
//                 minHeight: customHeight,
//                 padding: `0 calc(${customHeight} / 8)`,
//               }),
//               border: "0px",
//               ...(!!controlCustomStyle ? controlCustomStyle : {}),
//             }),
//             placeholder: (base) => ({
//               ...base,
//               // color: "#ffffff",
//               // fontWeight: "bold",
//               // textAlign: "center",
//               ...(!!customHeight && { fontSize: `calc(${customHeight} / 2.5)` }),
//               textOverflow: "ellipsis",
//               whiteSpace: "nowrap",
//               overflow: "hidden",
//               ...(!!placeholderCustomStyle ? placeholderCustomStyle : {}),
//             }),
//             menu: (base) => ({
//               ...base,
//               maxHeight: "400px",
//               overflowY: "auto",
//               scrollbarWidth: "thin",
//               msOverflowStyle: "auto",
//               zIndex: 999,
//               background: "transparent", // Fully transparent background
//               border: "2px solid #494949",
//               borderRadius: "8px",
//               backdropFilter: "blur(20px)", // Adds a slight blur effect for better readability
//               boxShadow: "none", // Removes any default shadow
//               "::-webkit-scrollbar": {
//                 width: "6px",
//                 height: "6px",
//               },
//               "::-webkit-scrollbar-thumb": {
//                 background: "#c1c1c1",
//                 borderRadius: "10px",
//               },
//               "::-webkit-scrollbar-track": {
//                 background: "#f1f1f1",
//                 borderRadius: "10px",
//               },
//             }),
//             option: (base, { isFocused, isSelected }) => ({
//               // ...base,
//               // textAlign: "center",
//               // backgroundColor: isSelected ? "#000000bd" : isFocused ? "#0000002b" : "transparent",
//               // color: isSelected ? "#f1f1f1" : isFocused ? "#121212" : "#000000" ,
//               // cursor: "pointer",
//               ...base,
//               textAlign: "center",
//               background: isSelected
//                 ? "rgba(0, 0, 0, 0.75)" // Darker black for selected option
//                 : isFocused
//                   ? "rgba(0, 0, 0, 0.4)" // Lighter black glass effect on hover
//                   : "transparent",
//               color: isSelected ? "#f1f1f1" : isFocused ? "#ffffff" : "#000000",
//               cursor: "pointer",
//               backdropFilter: isFocused ? "blur(8px)" : "none", // Adds blur effect for glassmorphism
//               // borderRadius: "5px",
//               transition: "background 0.3s ease, color 0.3s ease", // Smooth transition effect
//             }),
//             multiValue: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 margin: `0 calc(${customHeight} / 10)`,
//                 padding: `0 calc(${customHeight} / 10)`,
//               }),
//             }),
//             multiValueLabel: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`,
//               }),
//             }),
//             multiValueRemove: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`,
//               }),
//             }),
//           }}
//           value={
//             isMulti
//               ? options.filter((option) => field?.value?.includes(option.value))
//               : options.find((option) => option.value === field.value)
//           }
//           isClearable
//         />       
//       </div>
//       {meta.touched && meta.error && (
//         <div className="text-red-600 text-xs mt-1">
//           {Array.isArray(meta.error) ? meta.error.join(", ") : meta.error}
//         </div>
//       )}  
//     </div>
//   );
// };

// export default Dropdown;

// import React from "react";
// import Select from "react-select";
// import { useField } from "formik";


// const toLowerCase = (str: unknown): string => {
//   if (typeof str === "string") {
//     return str.toLowerCase();
//   }
//   return ""; // Return an empty string or handle the case differently
// };

// function containsOther(targetString, array = ["other", "others"]) {
//   return array.some(word => targetString.includes(word));
// }

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[];
//   name: string;
//   placeHolder: string;
//   isMulti?: boolean;
//   labelClassNames?: string;
//   customHeight?: string;
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
//   disable?: boolean;
//   wantInputFieldForOtherOption?: boolean;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle,
//   disable,
//   wantInputFieldForOtherOption
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   // // Add "Other" option dynamically
//   // const options = [...options, { value: "other", label: "Other" }];

//   const handleChange = (selectedOptions: any) => {
//     if (isMulti) {
//       const values = selectedOptions.map((option: any) => option.value);
//       setValue(values);
//     } else {
//       const selectedValue = selectedOptions?.value || "";
//       setValue(selectedValue);
//     }
//   };

//   console.log("field.value ",field.value)

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {!!label && (
//         <label className={`block ${labelClassNames || ""}`} style={{ fontSize: `calc(${customHeight} / 3)` }}>
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}
//       <div className="flex items-center gap-2">
//         <div className="w-full border border-solid border-black-900 rounded-lg">
//           <Select
//             isDisabled={disable}
//             menuPlacement="top"
//             options={options}
//             isMulti={isMulti}
//             onChange={handleChange}
//             placeholder={placeHolder}
//             menuPortalTarget={document.body}
//             styles={{
//               menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//               control: (base) => ({
//                 ...base,
//                 borderRadius: "0.5rem",
//                 height: customHeight,
//                 minHeight: customHeight,
//                 padding: `0 calc(${customHeight} / 8)`,
//                 border: "0px",
//                 ...(controlCustomStyle || {}),
//               }),
//               placeholder: (base) => ({
//                 ...base,
//                 fontSize: `calc(${customHeight} / 2.5)`,
//                 textOverflow: "ellipsis",
//                 whiteSpace: "nowrap",
//                 overflow: "hidden",
//                 ...(placeholderCustomStyle || {}),
//               }),
//             }}
//             value={
//               isMulti
//                 ? options.filter((option) => field?.value?.includes(option.value))
//                 : (!!wantInputFieldForOtherOption ? options.find((option) =>  option.value === field?.value?.split(":")?.[0]) : options.find((option) =>  option.value === field.value))
//             }
//             isClearable
//           />
//         </div>

//         {/* Show input field when "Other" is selected */}
//         {!!wantInputFieldForOtherOption && containsOther(toLowerCase(field?.value)) && (
//           <div className="w-full border border-solid border-black-900 rounded-lg p-1.5">
//             <input
//               type="text"
//               className="w-full border border-solid border-black-900 rounded-lg text-xs"
//               placeholder="Please specify"
//               value={String(field?.value)?.split(":")?.[1] || ""}
//               onChange={(e) => {
//                 if(field?.value.includes(":")) {
//                   setValue(`${String(field?.value)?.split(":")?.[0]}:${e.target.value}`);
//                 } else {
//                   setValue(`${field?.value}:${e.target.value}`);
//                 }
//               }}
//             />
//           </div>)}
//       </div>

//       {meta.touched && meta.error && (
//         <div className="text-red-600 text-xs mt-1">
//           {Array.isArray(meta.error) ? meta.error.join(", ") : meta.error}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Dropdown;

// import React, { useState } from "react";
// import CreatableSelect from "react-select/creatable";
// import { useField } from "formik";

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[];
//   name: string;
//   placeHolder: string;
//   isMulti?: boolean;
//   labelClassNames?: string;
//   customHeight?: string;
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
//   disable?: boolean;
//   wantInputFieldForOtherOption?: boolean;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle,
//   disable,
//   wantInputFieldForOtherOption,
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   // State to manage options dynamically
//   const [customOptions, setCustomOptions] = useState([]);

//   // Handle selection change
//   const handleChange = (selectedOptions: any) => {
//     if (isMulti) {
//       const values = selectedOptions.map((option: any) => option.value);
//       setValue(values);
//     } else {
//       setValue(selectedOptions?.value || "");
//     }
//   };

//   // Handle new option creation
//   const handleCreate = (inputValue: string) => {
//     const newOption = { value: inputValue, label: inputValue };
//     setCustomOptions([...options, newOption]); // Add new option to options list
//     setValue(isMulti ? [...(field.value || []), inputValue] : inputValue);
//   };

//   const renderOptions = () => (!!customOptions?.length ? customOptions : options);

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {!!label && (
//         <label className={`block ${labelClassNames || ""}`} style={{ fontSize: `calc(${customHeight} / 3)` }}>
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}
//       <div className="flex items-center gap-2">
//         <div className="w-full border border-solid border-black-900 rounded-lg">
//           <CreatableSelect
//             isDisabled={disable}
//             menuPlacement="top"
//             options={renderOptions()}
//             isMulti={isMulti}
//             onChange={handleChange}
//             onCreateOption={handleCreate} // Allow user to add custom options
//             placeholder={placeHolder}
//             menuPortalTarget={document.body}
//             styles={{
//               menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//               control: (base) => ({
//                 ...base,
//                 borderRadius: "0.5rem",
//                 height: customHeight,
//                 minHeight: customHeight,
//                 padding: `0 calc(${customHeight} / 8)`,
//                 border: "0px",
//                 ...(controlCustomStyle || {}),
//               }),
//               placeholder: (base) => ({
//                 ...base,
//                 fontSize: `calc(${customHeight} / 2.5)`,
//                 textOverflow: "ellipsis",
//                 whiteSpace: "nowrap",
//                 overflow: "hidden",
//                 ...(placeholderCustomStyle || {}),
//               }),
//             }}
//             value={isMulti ? renderOptions().filter((option) => field?.value?.includes(option.value)) : renderOptions().find((option) => option.value === field.value)}
//             isClearable
//           />
//         </div>
//       </div>

//       {meta.touched && meta.error && (
//         <div className="text-red-600 text-xs mt-1">
//           {Array.isArray(meta.error) ? meta.error.join(", ") : meta.error}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Dropdown;

import React, { useMemo } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useField } from "formik";
interface DropdownProps {
  label?: string;
  required?: boolean;
  options: { value: string; label: string }[];
  name: string;
  placeHolder: string;
  isMulti?: boolean;
  labelClassNames?: string;
  customHeight?: string;
  controlCustomStyle?: any;
  placeholderCustomStyle?: any;
  disable?: boolean;
  isCreatable?: boolean;
}

function hasMissingElements(options, values) {
  return values.some(item => !options.some(entry => entry.value.toLowerCase() === item.toLowerCase()));
}

function addMissingElements(options, values) {
  values.forEach(item => {
    let isPresent = options.some(entry => entry.value.toLowerCase() === item.toLowerCase());

    if (!isPresent) {
      options.push({ value: item, label: item });
    }
  });

  return options;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  name,
  placeHolder,
  required,
  isMulti = false,
  labelClassNames,
  customHeight = "37px",
  controlCustomStyle,
  placeholderCustomStyle,
  disable,
  isCreatable,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const [customOption, setCustomOption] = React.useState([]);

  useMemo(() => {
    if (options?.length) {
      if (isMulti) {
        if (hasMissingElements(options, field.value) && !!field.value?.length) {
          setCustomOption(addMissingElements(options, field.value));
        } else {
          setCustomOption([...options]);
        }
      } else {
        if (!options.find((option) => option.value === field.value) && !!field.value?.length) {
          const newOption = { value: field.value, label: field.value };
          setCustomOption([...options, newOption]);
        } else {
          setCustomOption([...options]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.length]);

  const handleChange = (selectedOptions: any) => {
    if (isMulti) {
      const values = selectedOptions.map((option: any) => option.value);
      setValue(values);
    } else {
      const selectedValue = selectedOptions?.value || "";
      setValue(selectedValue);
    }
  };

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    setCustomOption(customOption => [...customOption, newOption]);
    if (isMulti) {
      setValue([...field.value, inputValue]); // Set the new value
    } else {
      setValue(inputValue); // Set the new value
    }
  };

  const getValue = () => {
    if (isMulti) {
      return customOption.filter((option) => field?.value?.includes(option.value));
    } else {
      return customOption.find((option) => option.value === field.value);
    }
  };

  return !!customOption?.length ? (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label className={`text-xs block ${labelClassNames || ""}`} /* style={{ fontSize: `calc(${customHeight} / 3)` }} */>
          {label}
          {required && <span className="text-red-700">*</span>}
        </label>
      )}
      <div className="flex items-center gap-2">
        <div className="w-full border border-solid border-black-900 rounded-lg">
        {isCreatable ? <CreatableSelect
            isDisabled={disable}
            menuPlacement="top"
            options={customOption}
            isMulti={isMulti}
            onChange={handleChange}
            onCreateOption={handleCreate}
            placeholder={placeHolder}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                borderRadius: "0.5rem",
                height: customHeight,
                minHeight: customHeight,
                padding: `0 calc(${customHeight} / 8)`,
                border: "0px",
                ...(controlCustomStyle || {}),
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                ...(placeholderCustomStyle || {}),
              }),
              menu: (base) => ({
                ...base,
                maxHeight: "400px",
                overflowY: "auto",
                scrollbarWidth: "thin",
                msOverflowStyle: "auto",
                zIndex: 999,
                background: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
                border: "2px solid rgba(0, 0, 0, 0.1)", // Light border
                borderRadius: "8px",
                backdropFilter: "blur(20px)", // Blur effect for glassmorphism
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
                "::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "rgba(0, 0, 0, 0.2)", // Scrollbar thumb color
                  borderRadius: "10px",
                },
                "::-webkit-scrollbar-track": {
                  background: "rgba(0, 0, 0, 0.05)", // Scrollbar track color
                  borderRadius: "10px",
                },
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                textAlign: "center",
                background: isSelected
                  ? "rgba(0, 123, 255, 0.8)" // Blue background for selected option
                  : isFocused
                    ? "rgba(0, 123, 255, 0.2)" // Light blue background on hover
                    : "transparent",
                color: isSelected ? "#fff" : isFocused ? "#000" : "#333", // Text color
                cursor: "pointer",
                backdropFilter: isFocused ? "blur(5px)" : "none", // Blur effect on hover
                transition: "background 0.3s ease, color 0.3s ease", // Smooth transition
              }),
            }}
            value={getValue()}
            isClearable
          /> : <Select
          isDisabled={disable}
          menuPlacement="top"
          options={customOption}
          isMulti={isMulti}
          onChange={handleChange}
          // onCreateOption={handleCreate}
          placeholder={placeHolder}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              borderRadius: "0.5rem",
              height: customHeight,
              minHeight: customHeight,
              padding: `0 calc(${customHeight} / 8)`,
              border: "0px",
              ...(controlCustomStyle || {}),
            }),
            placeholder: (base) => ({
              ...base,
              fontSize: "0.75rem",
              lineHeight: "1rem",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              ...(placeholderCustomStyle || {}),
            }),
            menu: (base) => ({
              ...base,
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
              zIndex: 999,
              background: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
              border: "2px solid rgba(0, 0, 0, 0.1)", // Light border
              borderRadius: "8px",
              backdropFilter: "blur(20px)", // Blur effect for glassmorphism
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
              "::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "::-webkit-scrollbar-thumb": {
                background: "rgba(0, 0, 0, 0.2)", // Scrollbar thumb color
                borderRadius: "10px",
              },
              "::-webkit-scrollbar-track": {
                background: "rgba(0, 0, 0, 0.05)", // Scrollbar track color
                borderRadius: "10px",
              },
            }),
            option: (base, { isFocused, isSelected }) => ({
              ...base,
              textAlign: "center",
              background: isSelected
                ? "rgba(0, 123, 255, 0.8)" // Blue background for selected option
                : isFocused
                  ? "rgba(0, 123, 255, 0.2)" // Light blue background on hover
                  : "transparent",
              color: isSelected ? "#fff" : isFocused ? "#000" : "#333", // Text color
              cursor: "pointer",
              backdropFilter: isFocused ? "blur(5px)" : "none", // Blur effect on hover
              transition: "background 0.3s ease, color 0.3s ease", // Smooth transition
            }),
          }}
          value={getValue()}
          isClearable
        />}
        </div>
      </div>
      {meta.touched && meta.error && (
        <div className="text-red-600 text-xs mt-1">
          {Array.isArray(meta.error) ? meta.error.join(", ") : meta.error}
        </div>
      )}
    </div>
  ) : <></>;
};

export default Dropdown;
