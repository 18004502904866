import axios from "axios";
import React, { createContext, useState, ReactNode, useEffect } from "react";

interface Country {
  COUNTRY_NAME: string;
  COUNTRY_CODE: string;
}

interface DropDownOptions {
  value: string;
  label: string;
}
interface Avatar {
  value: string;
  label: string;
  type: "default";
  image: string;
  video: string;
}

interface InstitutionData {
  countries: Country[];
  Instruction_Medium: DropDownOptions[];
  InstitutionType: DropDownOptions[];
  GradeLevels: DropDownOptions[];
  BoardType: DropDownOptions[];
  AddonPrograms: DropDownOptions[];
  InstituteImageTitles: DropDownOptions[];
  MaterialType: DropDownOptions[];
  SubjectOptions: DropDownOptions[];
  Avatars: Avatar[];
}

// Define the type for the context
interface AppContextType {
  globalData: InstitutionData;
  // setGlobalData: React.Dispatch<React.SetStateAction<object>>;
}

const defaultData = {
  countries: [],
  Instruction_Medium: [],
  InstitutionType: [],
  GradeLevels: [],
  BoardType: [],
  AddonPrograms: [],
  InstituteImageTitles: [],
  MaterialType: [],
  SubjectOptions: [],
  Avatars: [],
};

// Create the context with default values
export const AppContext = createContext<AppContextType | undefined>(undefined);

// Provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [globalData, setGlobalData] = useState<InstitutionData>(defaultData);

  const fetchMetaData = async () => {
    try {
      const response = await axios.get(
        `https://dev.soomuch.ai/api/v1/master/onboarding/metadata`
      );
      if (response?.data?.data) {
        const transformedData = Object.fromEntries(
          Object.entries(response?.data?.data).map(([key, value]) => {
            if (key === "countries") return [key, value]; // Keep countries as is
            return [
              key,
              (value as any)?.map((item) => ({ value: item, label: item })),
            ];
          })
        );
        setGlobalData((globalData) => transformedData as any);
      }
    } catch (err) {
      setGlobalData((globalData) => defaultData);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  return (
    <AppContext.Provider value={{ globalData }}>{children}</AppContext.Provider>
  );
};
