import React from "react";
import { Field, ErrorMessage } from "formik";

interface Option {
    label: string;
    value: string;
}

interface RadioButtonGroupProps {
    name: string;
    label?: string;
    labelClassNames?: string;
    required?: boolean;
    options: Option[];
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ name, label, options, labelClassNames, required }) => {
    return (
        <div className="w-full">
            {!!label && (
                <label className={`block text-white text-xs ${labelClassNames || ""}`}>
                    {label} {!!required && <span className="text-red-700">*</span>}
                </label>
            )}
            <div className="flex justify-between items-center gap-2 border border-solid border-black-900 rounded-lg py-2 px-2 mt-1">
                {options.map((option) => (
                    <label key={option.value} className="flex gap-1 items-center cursor-pointer">
                        <Field
                            type="radio"
                            name={name}
                            value={option.value}
                            className="form-radio text-blue-600"
                        />
                        <span className="text-xs">{option.label}</span>
                    </label>
                ))}
            </div>
            <ErrorMessage name={name} component="div" className="text-red-600 text-xs mt-2" />
        </div>
    );
};

export default RadioButtonGroup;
