import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import Lottie from "lottie-react";
import DotAnimation from "assets/images/DotAnimation.json";
import axios from "axios";

const TextArea: ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows,
    cols,
    customHeight,
    labelClassNames,
    disabled,
    autoGenerate,
    generate
}: {
    label?: string;
    required?: boolean;
    backgroundColor?: string;
    name: string;
    placeHolder?: string;
    rows?: number;
    cols?: number;
    customHeight?: string;
    disabled?: boolean;
    labelClassNames?: string;
    autoGenerate?: boolean;
    generate?: object;
}) => React.JSX.Element = ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows = 4,
    cols = 50,
    customHeight,
    labelClassNames,
    disabled,
    autoGenerate = false,
    generate = {}
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Function to generate random text
    const generateRandomText = () => {
        const words = ["Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit"];
        let randomText = "";
        for (let i = 0; i < 10; i++) {
            randomText += words[Math.floor(Math.random() * words.length)] + " ";
        }
        return randomText.trim();
    };

    // Handle refresh button click
    const handleRefresh = async (form) => {
        try {
            setIsLoading(isLoading => true);
            const response = await axios.post(`https://dev.soomuch.ai/api/v1/master/textGenerate`, generate);
            form.setFieldValue(name, response?.data?.data?.text);
            setIsLoading(isLoading => false);
        } catch(err) {
            setIsLoading(isLoading => false); 
        }
    };


    return (
        <div className="flex flex-col gap-2">
            {!!label && (
                <label
                    htmlFor={name}
                    style={{ fontSize: `12px` }}
                    className={`block ${labelClassNames || ""}`}
                >
                    {label} {!!required && <span className="text-red-500">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                className={`w-full border border-solid border-black-900 rounded-2xl py-1 px-2 relative`}
            >
                {isLoading && (
                    <div
                        className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10"
                        style={{ height: customHeight || "auto" }}
                    >
                        <Lottie animationData={DotAnimation} style={{ width: 90, height: 100 }} />
                    </div>
                )}
                <Field name={name}>
                    {({ field, form }) => (
                        <div className="relative">
                            <textarea
                                disabled={disabled}
                                id={name}
                                className={`rounded-2xl w-full py-2 px-2 text-gray-800 text-sm sm:text-base md:text-lg ${!!disabled ? "cursor-not-allowed" : ""}`}
                                placeholder={placeHolder || ""}
                                rows={rows}
                                cols={cols}
                                style={{
                                    backgroundColor: backgroundColor || "white",
                                    height: customHeight || "auto",
                                    padding: `calc(${customHeight} / 8)`,
                                    fontSize: `12px`,
                                    resize: "none", // Disable the resize handle
                                }}
                                required={required}
                                value={field.value}
                                onChange={(event) => form.setFieldValue(name, event.target.value)}
                            />
                            {!disabled && autoGenerate && (
                                <button
                                    type="button"
                                    onClick={() => handleRefresh(form)}
                                    className="absolute top-1 right-1 rounded-full hover:bg-gray-300"
                                    style={{ zIndex: 1, top: customHeight ? `calc(${customHeight} / 2 - 16px)` : "0.25rem" }}
                                    title="Generate Text"
                                >
                                    <img src={"/images/TextGenrator.svg"} className="w-7 h-7" alt="asdasd" />
                                </button>
                            )}
                        </div>
                    )}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="text-red-500 text-xs" />
        </div>
    );
};

export default TextArea;