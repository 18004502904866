import axios from "axios";
import { ErrorMessage, Field } from "formik";
import React, { useCallback, useContext, useState } from "react";
import _ from "lodash";
import { AppContext } from "state";

const Input: ({
  label,
  required,
  backgroundColor,
  name,
  type,
  placeHolder,
  customHeight,
  isMobile, // Added isMobile prop
  labelClassNames,
  mapField,
  mapName,
  customFontSize,
  customStyle,
  disable,
}: {
  label?: string;
  required?: boolean;
  backgroundColor?: string;
  name: string;
  mapName?: string;
  type?: string;
  placeHolder?: string;
  customHeight?: string;
  labelClassNames?: string;
  mapField?: boolean;
  customFontSize?: string;
  customStyle?: any;
  disable?: boolean;
  isMobile?: boolean; // Added isMobile prop
  dominCheck?: boolean; // Added isMobile prop
  countryCodeName?: string;
}) => React.JSX.Element = ({
  label,
  required,
  backgroundColor,
  name,
  type,
  placeHolder,
  mapField,
  mapName,
  labelClassNames,
  customHeight,
  customFontSize,
  customStyle,
  disable,
  isMobile, // Destructure isMobile
  dominCheck,
  countryCodeName,
}) => {
  const { globalData } = useContext(AppContext);
  const [domainIsExist, setDomainIsExist] = useState("");
  const updateLocationFromAddress = async (enteredAddress, name, form) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          enteredAddress
        )}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.results?.length > 0) {
        const location = response?.data?.results?.[0]?.geometry?.location;
        const formattedAddress =
          response?.data?.results?.[0]?.formatted_address;
        let city = "";
        let postalCode = "";
        let country = "";
        let state = "";
        response?.data?.results?.[0]?.address_components.forEach(
          (component) => {
            // if (component.types.includes('locality')) {
            //   city = component.long_name; // Direct city name
            // }
            if (component.types.includes("postal_code")) {
              postalCode = component.long_name; // Postal Code
            }
            if (component.types.includes("country")) {
              country = component.long_name; // Country Name
            }
            if (component.types.includes("administrative_area_level_3")) {
              city = component.long_name; // District-level city
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
          }
        );
        form.setFieldValue(mapName, {
          lat: location.lat,
          lng: location.lng,
          address: formattedAddress,
          city,
          state,
          country,
          postalCode,
        });
      }
    } catch (error) {}
  };

  const debouncedUpdateLocation = useCallback(
    _.debounce((item, mapName, form) => {
      updateLocationFromAddress(item, mapName, form);
    }, 500),
    [mapName, updateLocationFromAddress]
  );

  const handleChange = (event, form) => {
    const item = event.target.value;
    if (mapField && !!item) {
      debouncedUpdateLocation(item, mapName, form);
    }
    form.setFieldValue(name, item);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label className={`block text-white text-xs ${labelClassNames || ""}`}>
          {label} {!!required && <span className="text-red-700">*</span>}
        </label>
      )}
      <div
        className="flex items-center gap-2 w-full border border-solid border-black-900 rounded-lg py-[2.8px] px-2"
        style={{ backgroundColor: backgroundColor || "white" }}
      >
        {isMobile && (
          <Field name={countryCodeName || "COUNTRY_CODE"}>
            {({ field, form, meta }) => (
              <div className="relative">
                <select
                  {...field}
                  className={`w-20 border rounded-md px-2 py-1 text-xs focus:outline-none focus:ring-0 focus:border-transparent
                                        ${
                                          meta.touched && meta.error
                                            ? "border-red-600"
                                            : "border-gray-300"
                                        }
                                    `}
                >
                  <option value="">Select</option>
                  {globalData?.countries?.map((country) => (
                    <option
                      key={country.COUNTRY_CODE}
                      value={country.COUNTRY_CODE}
                    >
                      {/* {country.COUNTRY_NAME} */} {country.COUNTRY_CODE}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </Field>
        )}
        <div className="flex justify-between items-center w-full">
          <Field name={name}>
            {({ field, form }) => (
              <input
                disabled={disable}
                className="rounded-lg w-full px-2 text-xs md:text-sm placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent"
                placeholder={placeHolder || ""}
                style={{
                  backgroundColor: backgroundColor || "white",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: customFontSize || "0.75rem",
                  height: customHeight || "32px",
                  ...(!!customStyle ? customStyle : {}),
                }}
                type={type || "text"}
                required={required}
                value={field?.value?.address || field.value}
                onChange={(item) => {
                  let val = item.target.value;

                  // If the input type is "number", restrict negative values
                  if (type === "number") {
                    val = val.replace(/[^0-9]/g, ""); // Allow only positive numbers
                  }

                  handleChange({ target: { value: val } }, form);
                }}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scrolling inside the number input
                onKeyDown={(e) => {
                  if (
                    type === "number" &&
                    (e.key === "ArrowUp" || e.key === "ArrowDown")
                  ) {
                    e.preventDefault(); // Prevent increment/decrement on scroll
                  }
                }}
                onBlur={async () => {
                  if (dominCheck) {
                    try {
                      const response = await axios.get(
                        `https://dev.soomuch.ai/api/v1/master/institute/checkDomainDuplicate?domain_name=${field?.value}`
                      );
                      if (
                        !(
                          response?.data?.message === "Domain name is available"
                        )
                      ) {
                        setDomainIsExist("YES");
                        form.setFieldError(
                          name,
                          "Domain name is not available"
                        );
                      } else {
                        setDomainIsExist("NO");
                        form.setFieldError(name, "");
                      }
                    } catch (err) {
                      setDomainIsExist("");
                    }
                  }
                }}
              />
            )}
          </Field>
          {Boolean(dominCheck && !!domainIsExist) ? (
            <img
              src={
                domainIsExist === "YES"
                  ? "images/RedCross.svg"
                  : "images/GreenTick.svg"
              }
              className="w-6 h-6"
              alt="Loading"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Show validation errors for both fields when isMobile is true */}
      {isMobile && (
        <ErrorMessage
          name={`${name}_country`}
          component="div"
          className="text-red-600 text-xs mt-1"
        />
      )}
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-600 text-xs mt-1"
      />
    </div>
  );
};

export default Input;
